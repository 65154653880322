import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import SEOWebpage from '../../components/SEO/webpage'
import { useStaticQuery, graphql } from "gatsby"

export default function ServiceArea() {

  const pageData = useStaticQuery(graphql`
    query ServiceArea {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      allGraphCmsServiceArea {
        group(field: stateLocation) {
          nodes {
            slug
            stateLocation
            locationName
          }
          fieldValue
          field
        }
      }
      graphCmsServiceAreaPage {
        id
        pageHeaderSeo {
          metaDescription
          metaTitle
        }
        createdAt
        publishedAt
        updatedAt
        slug
        content {
          html
        }
      }
    }
  `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + "/" + pageData.graphCmsServiceAreaPage.slug + "/";
  const groupedStateLoc = pageData.allGraphCmsServiceArea.group;
  console.log(groupedStateLoc);


  return (
    <Layout>
      <Head
        title={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaTitle}
        pageDesc={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <SEOWebpage
        title={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaTitle}
        meta_description={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
        date_published={pageData.graphCmsServiceAreaPage.createdAt}
        date_modified={pageData.graphCmsServiceAreaPage.updatedAt}
      />
      <div className="pageSection">
        <div className="content" dangerouslySetInnerHTML={{ __html: pageData.graphCmsServiceAreaPage.content.html }}>
        </div>
        {groupedStateLoc.map((state, index) =>
          <div>
            <h2>{state.fieldValue}</h2>
            {state.nodes.map((place) => <p><a href={place.slug + '/'}>{place.locationName}</a></p>)}
          </div>
        )}
      </div>
    </Layout>
  )
}
